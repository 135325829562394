import type { ChipOwnProps } from "@mui/material";
import { Card, CardHeader, Chip } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import { StationConnectorPip as ConnectorPip } from "../../components/Pip";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Connector, Station } from "../../types/station.db";
import { compareConnectors } from "../../util/comparators";

function ConnectorsCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const items = useMemo<Connector[]>(
    () => Object.values(station.connectors).sort(compareConnectors),
    [station]
  );

  const columns: GridColumns<Connector> = useMemo(
    () => [
      {
        field: "color",
        headerName: "Connector",
        ...columnTypes.flexDefault,
        renderCell: (params) => <ConnectorPip connector={params.row} />,
        sortable: false,
      },
      {
        field: "label",
        headerName: "Label",
        ...columnTypes.flexPrimary,
        valueFormatter: (params) => params.value ?? "-",
      },
      {
        field: "name",
        headerName: "Name",
        ...columnTypes.flexDefault,
      },
      {
        field: "power",
        headerName: "Power",
        ...columnTypes.flexNumber,
        valueFormatter: (params) => {
          const kw = params.value / 1000;
          const formatted = kw > 20 ? Math.round(kw) : kw.toFixed(2);
          return `${formatted} kW`;
        },
      },
      {
        field: "status",
        headerName: "Status",
        ...columnTypes.flexDefault,
        minWidth: 250,
        valueGetter: (params) =>
          station.connector_status?.[params.row.id]?.status ?? "No Status",
        renderCell: (params) => {
          let color: ChipOwnProps["color"] = "info";
          switch (params.value) {
            case "Available":
              color = "success";
              break;
            case "In Use":
              color = "warning";
              break;
            case "Offline":
              color = "error";
              break;
            case "No Status":
              color = "info";
              break;
          }
          return (
            <Chip
              label={params.value}
              variant={"outlined"}
              color={color}
              size={"small"}
            />
          );
        },
      },
      {
        field: "created_at",
        headerName: "First created",
        ...columnTypes.createdAt,
      },
      {
        field: "updated_at",
        headerName: "Last updated",
        ...columnTypes.updatedAt,
      },
    ],
    [station]
  );

  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <Card>
      <CardHeader
        title={
          items.length > 0
            ? `Connectors (${numberFormatter.format(items.length)})`
            : "Connectors"
        }
      />
      <DataGrid
        rows={items}
        columns={columns}
        {...standardProps}
        pageSize={10}
        sx={{
          ".MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </Card>
  );
}

export default ConnectorsCard;

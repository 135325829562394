import { Stack, TextField } from "@mui/material";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getFirestore,
  runTransaction,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import type { FormEventHandler } from "react";
import React, { useCallback, useEffect, useState } from "react";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { updatedAt } from "../../util/firebase";
import { formatStationAddress } from "../../util/stdlib";

function MoveNrelDialog({
  open,
  handleClose,
  station,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [target, setTarget] = useState<WithDocRef<Station> | null>(null);
  const [targetId, setTargetId] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // Lookup target
  useEffect(() => {
    if (targetId.length !== 20) {
      setTarget(null);
      setError(null);
      return;
    }

    const db = getFirestore();
    const docRef = doc(collection(db, "stations"), targetId);
    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        const station = {
          id: doc.id,
          docRef: doc.ref,
          ...doc.data(),
        } as WithDocRef<Station>;
        setTarget(station);
        setError(
          station.nrel_id ? "The target station already has an NREL ID." : null
        );
      } else {
        setTarget(null);
        setError("No station found with this ID.");
      }
    });
  }, [targetId]);

  const onSubmit = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      // Validation
      if (target === null || target.nrel_id) return;

      try {
        setLoading(true);

        const db = getFirestore();
        await runTransaction(db, async (transaction) => {
          transaction.update(target.docRef, {
            nrel_id: station.nrel_id,
            khloud_guid: deleteField(),
            ...updatedAt,
          });
          transaction.update(station.docRef, {
            nrel_id: null,
            khloud_guid: deleteField(),
            ...updatedAt,
          });
        });
        enqueueSnackbar("Updated station");
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update station", { variant: "error" });
      } finally {
        handleClose();
      }
    },
    [handleClose, station, enqueueSnackbar, target]
  );

  return (
    <FormDialog
      title={"Move NREL ID"}
      open={open}
      onCancel={handleClose}
      onSubmit={onSubmit}
      isSubmitting={loading}
      submitButtonLabel={"Move"}
      readyToSubmit={target !== null && !error}
    >
      <Stack mt={6} spacing={6}>
        <TextField
          label="Target ID"
          value={targetId}
          onChange={(event) => setTargetId(event.target.value)}
          helperText={
            error ||
            (target
              ? [target.name, formatStationAddress(target)].join(", ")
              : "Enter the 20-character identifier of the target station.")
          }
          error={Boolean(error)}
          fullWidth
        />
      </Stack>
    </FormDialog>
  );
}

export default MoveNrelDialog;

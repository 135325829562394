import { Link, Rating, Stack, Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";

function GeneralCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const numberFormatter = Intl.NumberFormat("en-US");

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (station.timestamps.created_at as Timestamp)?.toDate();
  const updatedAt = (station.timestamps.updated_at as Timestamp)?.toDate();

  return (
    <PartitionedCard title={"General"} spacing={4}>
      <PartitionedCardItem title={"NREL ID"}>
        {station.nrel_id ?? "-"}
      </PartitionedCardItem>

      {station.khloud_id && (
        <PartitionedCardItem title={"KH ID"}>
          {station.khloud_id}
        </PartitionedCardItem>
      )}

      {station.khloud_guid && (
        <PartitionedCardItem title={"KH GUID"}>
          {station.khloud_guid}
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Photos"}>
        {numberFormatter.format(station.photo_count)}
      </PartitionedCardItem>

      <PartitionedCardItem title={"Reviews"}>
        {numberFormatter.format(station.review_count)}
      </PartitionedCardItem>

      <PartitionedCardItem title={"Rating"} alignItems={"center"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Rating value={station.rating} precision={0.5} readOnly />
          <Typography
            component={"span"}
            variant={"body2"}
            color={"text.secondary"}
          >
            {station.rating.toFixed(1)}{" "}
            {station.review_count === 1
              ? "(1 review)"
              : `(${station.review_count} reviews)`}
          </Typography>
        </Stack>
      </PartitionedCardItem>

      <PartitionedCardItem title={"First Created"}>
        {createdAt ? (
          <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
            </Typography>
          </Tooltip>
        ) : (
          <Typography>-</Typography>
        )}
      </PartitionedCardItem>

      <PartitionedCardItem title={"Last Updated"}>
        {updatedAt ? (
          <Tooltip title={format(updatedAt, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(updatedAt, { addSuffix: true })}
            </Typography>
          </Tooltip>
        ) : (
          <Typography>-</Typography>
        )}
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default GeneralCard;

import { FormControlLabel, Switch } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { updatedAt } from "../../util/firebase";

function SettingsCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const updateSetting = useCallback(
    (field: string, value: boolean) => {
      if (!(field in station)) {
        throw Error(`Invalid field name: ${field}`);
      }
      updateDoc(station.docRef, {
        [field]: value,
        ...updatedAt,
      }).catch((err) => {
        console.error(err);
        enqueueSnackbar("Failed to update station.", { variant: "error" });
      });
    },
    [station, enqueueSnackbar]
  );

  return (
    <PartitionedCard title={"Settings"} spacing={2}>
      <PartitionedCardItem title={"Restricted Access"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={station.is_access_restricted}
              onChange={(event) => {
                updateSetting("is_access_restricted", event.target.checked);
              }}
            />
          }
          label={station.is_access_restricted ? "Enabled" : "Disabled"}
        />
      </PartitionedCardItem>

      <PartitionedCardItem title={"Coming Soon"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={station.is_coming_soon}
              onChange={(event) => {
                updateSetting("is_coming_soon", event.target.checked);
              }}
            />
          }
          label={station.is_coming_soon ? "Enabled" : "Disabled"}
        />
      </PartitionedCardItem>

      <PartitionedCardItem title={"Offline"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={station.is_offline}
              onChange={(event) => {
                updateSetting("is_offline", event.target.checked);
              }}
            />
          }
          label={station.is_offline ? "Enabled" : "Disabled"}
        />
      </PartitionedCardItem>

      <PartitionedCardItem title={"Published"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={station.publish}
              disabled={station.archived}
              onChange={(event) => {
                updateSetting("publish", event.target.checked);
              }}
            />
          }
          label={station.publish ? "Enabled" : "Disabled"}
        />
      </PartitionedCardItem>

      <PartitionedCardItem title={"Archived"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={station.archived}
              onChange={(event) => {
                updateSetting("archived", event.target.checked);

                if (event.target.checked) {
                  updateSetting("publish", false);
                }
              }}
            />
          }
          label={station.archived ? "Enabled" : "Disabled"}
        />
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default SettingsCard;
